import React from "react"

import { graphql } from "gatsby"

import Head from "../components/global/head"
import HeroIntroBanner from "../components/modules/hero_intro_banner"

const FormSubmissionSuccess = ({ data }) => {
  const {
    metaTitle,
    pageTitle,
    message,
    sectionName,
  } = data.datoCmsFormSubmissionConfirmation
  console.log("sectionName", sectionName)
  return (
    <>
      <Head
        bodyClassname={"body__formSubmissionSuccess"}
        title={metaTitle}
        url="https://www.choosetap.com.au/thanks"
        description=""
        keywords=""
      />

      <section className="pages__contact">
        <HeroIntroBanner
          title={pageTitle}
          sectionName={sectionName}
          themeColor="grey"
          shortDescription={message}
          isSubmissionConfirmInstance={true}
        />
      </section>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    datoCmsFormSubmissionConfirmation(rootPath: { eq: $slug }) {
      rootPath
      metaTitle
      pageTitle
      message
      sectionName
    }
  }
`

export default FormSubmissionSuccess
